import userNavComp from "./../../components/userNav/index.vue";
import userMobileNavComp from "./../../components/userMobileNav/index.vue";

export default {
    name: "panel",
    components: { userNavComp, userMobileNavComp },
    props: [],
    data() {
        return {
            openMenu: false,
        };
    },
    computed: {},
    mounted() {},
    methods: {},
};
