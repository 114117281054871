import * as types from "./../../store/types";
import { mapActions, mapGetters } from "vuex";
import ThemingService from "./../../theme/theme";
import themeSwitch from "../themeSwitch/index.vue";

const merchantNavigations = () => import("./merchantNavigations/index.vue");
const adminNavigations = () => import("./adminNavigations/index.vue");

export default {
    name: "user-nav",
    components: { merchantNavigations, adminNavigations, themeSwitch },
    props: [],
    data() {
        return {
            merchantIdValue: localStorage.getItem("bitrahAccessToken"),
            themeToggle: localStorage.getItem("theme") !== "darkTheme",
            texttoken: "",
        };
    },
    computed: {
        ...mapGetters({
            userInfo: types.GET_USER_INFO,
        }),
    },
    mounted() {
        // this.texttoken=localStorage.getItem("accessToken");
    },
    created() {

    },
    methods: {
        ...mapActions({
            checkIsAuth: types.ACTION_CHECK_IS_AUTH,
        }),
        changeTheme() {
            let a = new ThemingService();
            if (this.themeToggle) {
                a.changeTheme("darkTheme");
            } else {
                a.changeTheme("lightTheme");
            }
        },
    },
};
