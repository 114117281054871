import ThemingService from "../../theme/theme";
import { mapActions, mapGetters } from "vuex";
import * as types from "../../store/types";

export default {
    name: "theme-switch",
    components: {},
    props: [],
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            isThemeDark: types.GET_IS_DARK,
        }),
    },
    mounted() {
        this.isThemeDarkAction();
    },
    methods: {
        ...mapActions({
            isThemeDarkAction: types.ACTION_IS_DARK,
        }),
        changeTheme() {
            let a = new ThemingService();
            if (!this.isThemeDark) {
                a.changeTheme("darkTheme");
            } else {
                a.changeTheme("lightTheme");
            }
            this.isThemeDarkAction();
        },
    },
};
