import { mapActions, mapGetters } from "vuex";
import * as types from "../../store/types";
import themeSwitch from "../themeSwitch/index.vue";

const merchantNavigations = () =>
    import("./../userNav/merchantNavigations/index.vue");
const adminNavigations = () =>
    import("./../userNav/adminNavigations/index.vue");
export default {
    name: "user-mobile-nav",
    components: { merchantNavigations, adminNavigations, themeSwitch },
    props: [],
    data() {
        return {
            merchantIdValue: localStorage.getItem("bitrahAccessToken"),
        };
    },
    computed: {
        ...mapGetters({
            userInfo: types.GET_USER_INFO,
        }),
    },
    mounted() {},
    methods: {
        ...mapActions({
            checkIsAuth: types.ACTION_CHECK_IS_AUTH,
        }),
    },
};
